import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../redux/ReportsRedux';
import { useIntl } from 'react-intl';
import SortButton from "../../SortButton";
import { numSpaceFormater } from '../../../../../helpers/formater';
import { GoogleCampaignsList, SEMReportDetails } from '../../../models/SEMReports';

type SortKeys = any;
type SortOrder = "ascn" | "desc";

interface GoogleAdsGroupProps {
    tabKey: string | number;
}

const GoogleAdsList: React.FC<GoogleAdsGroupProps> = ({ tabKey }) => {
    const intl = useIntl();
    const details = useSelector(selectors.getReportDetails) as SEMReportDetails;
    const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");
    const [sortKey, setSortKey] = useState<SortKeys>();
    const [googleCampaignsList, setGoogleCampaignsList] = useState<GoogleCampaignsList>();

    const sortListByParam = (param: any, isAscending: string = 'ascn') => {
        if (googleCampaignsList) {
            const sortedList = [...googleCampaignsList];
            sortedList.sort((a, b) => (a[param] > b[param]) ? 1 : -1);
            if (isAscending !== 'ascn') {
                sortedList.reverse();
            }
            setGoogleCampaignsList(sortedList);
        }
    };

    useEffect(() => {
        if (details.googleAdsCampaignList) {
            Object.values(details.googleAdsCampaignList).map((key) => {
                if (tabKey === key.id) {
                    setGoogleCampaignsList(key.data);
                }
            });
        }
    }, [tabKey, details.googleAdsCampaignList]);

    const changeSort = (key: SortKeys) => {
        const newSortOrder = sortOrder === "ascn" ? "desc" : "ascn";
        setSortOrder(newSortOrder);
        setSortKey(key);
        sortListByParam(key, newSortOrder);
    };

    return (
        <>
            {googleCampaignsList ? (
                <div className='card overlay rounded'>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>
                                {intl.formatMessage({ id: 'CAMPAIGNS_REPORT_EFFICIENCY_TITLE' })}
                            </span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            {googleCampaignsList && (
                                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-50px'>
                                            <div className='d-flex'>
                                                {intl.formatMessage({ id: 'CAMPAIGN_NAME' })}
                                                <SortButton
                                                    columnKey={'campaign_name'}
                                                    onClick={() => changeSort('campaign_name')}
                                                    sortOrder={sortOrder}
                                                    sortKey={sortKey}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex justify-content-center'>
                                                {intl.formatMessage({ id: 'CTR' })}
                                                <SortButton
                                                    columnKey={'ctr'}
                                                    onClick={() => changeSort('ctr')}
                                                    sortOrder={sortOrder}
                                                    sortKey={sortKey}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex justify-content-center'>
                                                {intl.formatMessage({ id: 'COST' })}
                                                <SortButton
                                                    columnKey={'cost_micros'}
                                                    onClick={() => changeSort('cost_micros')}
                                                    sortOrder={sortOrder}
                                                    sortKey={sortKey}
                                                />
                                            </div>
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            {intl.formatMessage({ id: 'CPC' })}
                                        </th>
                                        <th className='min-w-50px text-center'>
                                            <div className='d-flex justify-content-center'>
                                                {intl.formatMessage({ id: 'CLICKS' })}
                                                <SortButton
                                                    columnKey={'clicks'}
                                                    onClick={() => changeSort('clicks')}
                                                    sortOrder={sortOrder}
                                                    sortKey={sortKey}
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {googleCampaignsList.map((single) => (
                                        <tr key={single.campaign_id}>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <div className='text-dark fw-bolder fs-8'>{single.campaign_name}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='text-dark fs-8'>{(Number(single.ctr) * 100).toFixed(2)}%</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='text-dark fs-8'>{numSpaceFormater(Number(single.cost_micros).toFixed(2))} zł</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='text-dark fs-8'>{numSpaceFormater(Number(single.cpc).toFixed(2))} zł</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-center'>
                                                    <div className='text-dark fs-8'>{numSpaceFormater(Number(single.clicks).toFixed(0))}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};

export default GoogleAdsList;
