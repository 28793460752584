import React, {useState} from 'react'
import clsx from 'clsx'
import {IReportDetails, SEM_TAB_NAME} from '../../models/Reports'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { selectors } from '../../redux/ReportsRedux'
import GadsPrintView from "../Print/GadsPrintView";
import {KTSVG} from "../../../../../_metronic/helpers";

interface TabsNavProps {
  tab: string
  setTab: (data: string) => void
}

const SemTabsNav: React.FC<TabsNavProps> = ({tab, setTab}) => {
  const intl = useIntl()
  const reportData = useSelector(selectors.getReportData) as IReportDetails
  const [open, setOpen] = useState(false);


  return (
      <ul
          className='nav nav-tabs-report nav-stretch nav-fixed-top nav-line-tabs fw-bold fs-6 flex-nowrap'
          role='tablist'
      >
        {reportData && reportData.details && reportData.details.googleAdsConfig && (
            <li className='nav-item action-btn'>
              <div
                  className={clsx(`nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`, {
                    active: tab === SEM_TAB_NAME.GADS,
                  })}
                  onClick={() => setTab(SEM_TAB_NAME.GADS)}
                  role='tab'
              >
                {intl.formatMessage({id: SEM_TAB_NAME.GADS})}
              </div>
            </li>
        )}

        {reportData && reportData.details && reportData.details.GA4config && reportData.details.GA4config.status && (
            <li className='nav-item action-btn'>
              <div
                  className={clsx(`nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`, {
                    active: tab === SEM_TAB_NAME.GA4,
                  })}
                  onClick={() => setTab(SEM_TAB_NAME.GA4)}
                  role='tab'
              >
                {intl.formatMessage({id: SEM_TAB_NAME.GA4})}
              </div>
            </li>

        )}




        <li className='nav-item action-btn'>
          <div
              className={clsx(`nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`, {
                active: tab === SEM_TAB_NAME.COMMENT,
              })}
              onClick={() => setTab(SEM_TAB_NAME.COMMENT)}
              role='tab'
          >
            {intl.formatMessage({id: SEM_TAB_NAME.COMMENT})}
          </div>
        </li>
        {reportData && reportData.attachments && (
            <li className='nav-item action-btn'>
              <div
                  className={clsx(`nav-link nav-link-report fw-bolder fs-xl-4 fs-6 px-5`, {
                    active: tab === SEM_TAB_NAME.ATTACHMENTS,
                  })}
                  onClick={() => setTab(SEM_TAB_NAME.ATTACHMENTS)}
                  role='tab'
              >
                {intl.formatMessage({id: SEM_TAB_NAME.ATTACHMENTS})}
              </div>
            </li>
        )}
        {reportData && reportData.type == 'sem' && (
            <li className='nav-item action-btn'>
              <div onClick={() => setOpen(true)}
                   className={clsx(`nav-link nav-link-report fw-bolder fs-xl-4 fs-6 px-5`, {
                     active: tab === SEM_TAB_NAME.PRINT,
                   })}
              >
                <div>
                  <KTSVG
                      path='/media/sempai-images/icons/Icon-feather-download.svg'
                      className='svg-icon-3 svg-icon-white'
                  />
                  <span className='px-2'>{intl.formatMessage({id: SEM_TAB_NAME.PDF})}</span>
                </div>
                {open ? <GadsPrintView closePopup={() => setOpen(false)} /> : null}

              </div>
            </li>
        )}
      </ul>
  )
}

export default SemTabsNav
