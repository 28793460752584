import ChartLinesWidget from '../../Widgets/ChartLinesWidget'
import StatisticWidget from '../../../../common/StatisticWidget/StatisticWidget'
import {useSelector} from 'react-redux'
import {selectors} from '../../../redux/ReportsRedux'
import {ClicksToRevenuePerDate, GadsClicksToRevenuePerDate, GoogleAnalyticSummary} from '../../../models/Reports'
import {useEffect, useState} from 'react'
import {numSpaceFormater} from '../../../../../helpers/formater'
import {useIntl} from 'react-intl'
import {GoogleAdsSummary, GoogleCampaignsList, SEMReportDetails} from '../../../models/SEMReports'
import BasicDataWidget from "../../SeoStatistic/SeoWidgets/BasicDataWidget";
import {SumBasic} from "../../../models/AbstractReportDetails";
import {toAbsoluteUrl} from "../../../../../../_metronic/helpers";
import ForecastPopup from "./ForecastPopup";


interface GoogleAdsGroupProps {
    tabKey: string | number
    onLoad?: () => void;
}

interface GadsSumBasic {
    clicks: string
    cost_micros: string
    conversions: string
    conversions_value: string
    ctr: string
    cpc: string
    impressions: string
    conversion_cost: string
    conversion_rate: string
    conversion_to_cost: string

}


const GoogleAdsGroup: React.FC<GoogleAdsGroupProps> = ({tabKey, onLoad }) => {
    const intl = useIntl()
    const details = useSelector(selectors.getReportDetails) as SEMReportDetails

    const [gadsClicksToRevenueChart, setGadsClicksToRevenueChart] = useState<GadsClicksToRevenuePerDate>()
    const [gadsSumBasic, setGadsSumBasic] = useState<GadsSumBasic>()

    var multiplier: number = 1;
    if(details.googleAdsConfig && details.googleAdsConfig.multiplier){
        multiplier = details.googleAdsConfig.multiplier / 100;
    }

    useEffect(() => {
        if(onLoad){
            onLoad();
        }
    }, [onLoad]);

    useEffect(() => {


        const clicksArray: any[] = []
        const datesArray: any[] = []
        const conversionsArray: any[] = []


        //if key = 1 use details.googleAdsSumData.sumDaily
        if (tabKey == '1') {

            details.googleAdsSumData.sumDaily.forEach((item: any) => {

                clicksArray.push(item.clicks)
                datesArray.push(item.date)
                conversionsArray.push((item.conversions * multiplier).toFixed(2))

            })
            setGadsClicksToRevenueChart({
                clicks: clicksArray,
                dates: datesArray,
                conversions: conversionsArray,

            })

            if (details.googleAdsSumData.sumBasic) {
                Object.values(details.googleAdsSumData.sumBasic).map((key) => {
                    setGadsSumBasic({
                        clicks: numSpaceFormater(key.clicks),
                        cost_micros: numSpaceFormater(Number(key.cost_micros).toFixed(2)) + ' zł',
                        ctr: Number(key.ctr).toFixed(2) + ' %',
                        cpc: Number(key.cpc).toFixed(2)+ ' zł',
                        impressions: numSpaceFormater(key.impressions),
                        conversions: numSpaceFormater((Number(key.conversions) * multiplier).toFixed(2)),
                        conversions_value: numSpaceFormater((Number(key.conversions_value) * multiplier).toFixed(2)) + ' zł',

                        //multiplier used in microservice
                        conversion_rate: Number(key.conversion_rate).toFixed(2) + ' %',
                        conversion_to_cost: Number(key.conversion_to_cost).toFixed(2),
                        conversion_cost: Number(key.conversion_cost).toFixed(2) + ' zł',
                    })
                    // console.log(key);
                })
            }


        } else {
            if (details.GoogleAdsDaily) {
                Object.values(details.GoogleAdsDaily).map((key) => {
                    if (tabKey == key.id) {

                        key.data.forEach((item: any) => {

                            clicksArray.push(item.clicks)
                            datesArray.push(item.date)
                            conversionsArray.push(item.conversions)

                        })
                        setGadsClicksToRevenueChart({
                            clicks: clicksArray,
                            dates: datesArray,
                            conversions: conversionsArray,
                        })

                    }
                })
            }

        }

    }, [tabKey]);


    return (
        <>

            <div className='row'>
                <div className='pb-10'>
                    <div className='d-flex justify-content-between'>
                        <h3 className='fw-bolder text-dark'>
                            {intl.formatMessage({id: 'SEM_REPORT_TITLE'})}{' '}
                            {details.googleAdsConfig && details.googleAdsConfig.month && details.googleAdsConfig.year && (
                                <>
                                {(details.googleAdsConfig.month)}{' '}{(details.googleAdsConfig.year)}
                                </>
                            )}

                        </h3>
                        {details.googleAdsConfig && details.googleAdsConfig.type == 0 && tabKey == 1 && (
                            <ForecastPopup/>
                        )}

                    </div>
                    <div className='text-gray-400 fw-bold fs-6'>
                        {intl.formatMessage({id: 'SEM_REPORT_SUBTITLE'})}
                        {/*{details.googleAdsConfig.type == 0 && tabKey == 1 && (*/}
                        {details.googleAdsConfig && details.googleAdsConfig.generate_date && tabKey == 1 && (
                            <>
                                {' na dzień: '}{(details.googleAdsConfig.generate_date)}
                            </>
                        )}

                    </div>
                </div>
            </div>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-12'>
                    {gadsClicksToRevenueChart && (
                        <ChartLinesWidget
                            className='card-xl-stretch mb-xl-8'
                            chartId='sem-GoogleAdsGroup'
                            dateArray={gadsClicksToRevenueChart.dates}
                            firstDataSet={gadsClicksToRevenueChart.clicks}
                            secondDataSet={gadsClicksToRevenueChart.conversions}
                            firstDataSetTitle={intl.formatMessage({id: 'CLICKS'})}
                            secondDataSetTitle={intl.formatMessage({id: 'CONVERSIONS'})}
                        />
                    )}
                </div>
            </div>


            {tabKey == '1' && gadsSumBasic && (

                <div data-testid='basic-data-googleAnalytic' className='d-flex flex-wrap pb-5'>


                    <div className='row g-1 w-100'>
                        <div className='col-12'>
                            <div className='row g-0 justify-content-between'>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/Group-129.svg'
                                        title={intl.formatMessage({id: 'CLICKS'})}
                                        value={gadsSumBasic.clicks}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/cost_per_conversion_2.svg'
                                        title={intl.formatMessage({id: 'COST'})}
                                        value={gadsSumBasic.cost_micros}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/Group-159.svg'
                                        title='CTR'
                                        value={gadsSumBasic.ctr}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/cost_micros.svg'
                                        title='CPC'
                                        value={gadsSumBasic.cpc}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/monitor-with-eye.svg'
                                        title={intl.formatMessage({id: 'IMPRESSIONS'})}
                                        value={gadsSumBasic.impressions}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='g-2 row d-flex justify-content-between'>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/conversions.svg'
                                        title={intl.formatMessage({id: 'CONVERSIONS'})}
                                        // title='fgh'
                                        value={gadsSumBasic.conversions}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>


                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/cost_per_conversion.svg'
                                        title={intl.formatMessage({id: 'CONVERSION_COST'})}
                                        value={gadsSumBasic.conversion_cost}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/conversion_from_interaction_rate_2.svg'
                                        title={intl.formatMessage({id: 'CONVERSION_RATE'})}
                                        value={gadsSumBasic.conversion_rate}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/conversion_value2.svg'
                                        title={intl.formatMessage({id: 'CONVERSION_VALUE'})}
                                        value={gadsSumBasic.conversions_value}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>
                                <div className='col-2'>
                                    <BasicDataWidget
                                        icon='/media/sempai-images/icons/conversion_value_3.svg'
                                        title={intl.formatMessage({id: 'CONVERSION_TO_COST'})}
                                        value={gadsSumBasic.conversion_to_cost}
                                        valueFSzClass='fs-4'
                                        className='d-flex border-right ps-3 py-5'
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/*{googleAnalytic && (*/}
            {/*  <div className='row pb-5'>*/}
            {/*    <div className='fs-5'>*/}
            {/*      {`Na przestrzeni miesiąca kampania wygenerowała X% przychodów i X% ruchu w serwisie.*/}
            {/*      Przychody wyniosły ${numSpaceFormater(*/}
            {/*        googleAnalytic.transactions_revenue*/}
            {/*      )}zł przy wydatkach na kampanię na poziomie ${numSpaceFormater(*/}
            {/*        Number(googleAnalytic.ad_cost).toFixed(2)*/}
            {/*      )}zł i ROASie ${numSpaceFormater(Number(googleAnalytic.roas).toFixed(1))}%.`}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}


        </>
    )
}

export default GoogleAdsGroup
