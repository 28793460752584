import clsx from "clsx";
import { KTSVG } from "../../../../../_metronic/helpers";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { TAB_NAME } from "../../models/Reports";

interface IPrintViewProps {
    closePopup: () => void;
}

interface ITabsNavProps {
    tab: string;
    setTab: (data: string) => void;
    reportData: any;
    PrintViewComponent: React.ComponentType<IPrintViewProps>;
    hasData?: boolean;
}

const TabsNav: React.FC<ITabsNavProps> = ({
                                              tab,
                                              setTab,
                                              reportData,
                                              PrintViewComponent,
                                              hasData = true
                                          }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    return (
        <ul
            className="nav nav-tabs-report nav-stretch nav-fixed-top nav-line-tabs fw-bold fs-6 flex-nowrap"
            role="tablist"
        >
            {reportData && reportData.details && hasData && (
                <li className="nav-item action-btn">
                    <div
                        className={clsx(
                            `nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`,
                            {
                                active: tab === TAB_NAME.ANALYSIS,
                            }
                        )}
                        onClick={() => setTab(TAB_NAME.ANALYSIS)}
                        role="tab"
                    >
                        {intl.formatMessage({ id: TAB_NAME.ANALYSIS })}
                    </div>
                </li>
            )}

            <li className="nav-item action-btn">
                <div
                    className={clsx(
                        `nav-link nav-link-report fs-xl-3 font-weight-bold fs-5 px-20 me-5`,
                        {
                            active: tab === TAB_NAME.COMMENT,
                        }
                    )}
                    onClick={() => setTab(TAB_NAME.COMMENT)}
                    role="tab"
                >
                    {intl.formatMessage({ id: TAB_NAME.COMMENT })}
                </div>
            </li>
            <li className="nav-item action-btn">
                <div
                    onClick={() => setOpen(true)}
                    className={clsx(
                        `nav-link nav-link-report fw-bolder fs-xl-4 fs-6 px-5`,
                        {
                            active: tab === TAB_NAME.PRINT,
                        }
                    )}
                >
                    <div>
                        <KTSVG
                            path="/media/sempai-images/icons/Icon-feather-download.svg"
                            className="svg-icon-3 svg-icon-white"
                        />
                        <span className="px-2">
              {intl.formatMessage({ id: TAB_NAME.PRINT })}
            </span>
                    </div>
                    {open ? <PrintViewComponent closePopup={() => setOpen(false)} /> : null}
                </div>
            </li>
        </ul>
    );
};

export default TabsNav;
