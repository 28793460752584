import React, {useState} from 'react'
import { selectors } from "../../redux/ReportsRedux";
import { IReportDetails } from "../../models/Reports";
import { useSelector } from "react-redux";
import TabsNav from "../Common/TabsNav";
import AllegroPrintView from "./Print/AllegroPrintView";

const AllegroTabsNav: React.FC<any> = ({ tab, setTab }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails;
    const hasData = Boolean(
      reportData?.details?.allegroDaily
    );
    return (
        <TabsNav
            tab={tab}
            setTab={setTab}
            reportData={reportData}
            hasData={hasData}
            PrintViewComponent={AllegroPrintView}
        />
    );
};

export default AllegroTabsNav;
